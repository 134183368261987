// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBCju52tN3tBT7V-bWdUH6TNVAyvzcEkpo',
  authDomain: 'react-redux-restaurantapp.firebaseapp.com',
  databaseURL:
    'https://react-redux-restaurantapp-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'react-redux-restaurantapp',
  storageBucket: 'react-redux-restaurantapp.appspot.com',
  messagingSenderId: '177155268556',
  appId: '1:177155268556:web:f320684642f05b7f6b0769',
  measurementId: 'G-Z02R6TFW36',
};

// Initialize Firebase
const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const storage = getStorage(app);
// const analytics = getAnalytics(app);

export { app, firestore, storage };
