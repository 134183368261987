import React, { useEffect, useRef, useState } from 'react';
import HomeContainer from './HomeContainer';
import { motion } from 'framer-motion';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import RowContainer from './RowContainer';
import { useStateValue } from '../context/StateProvider';
import MenuContainer from './MenuContainer';
import CartContainer from './CartContainer';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { LeftArrow } from './scrolling/leftArrows.jsx';
import { RightArrow } from './scrolling/rightArrow';
import { Card } from './scrolling/card';

const MainContainer2 = () => {
  console.log('Hello from Maincontainer2');

  const [{ foodItems, cartShow }, dispatch] = useStateValue();

  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  return (
    <div className="w-full h-auto flex flex-col items-center justify-center">
      <HomeContainer />
      <section className="w-full my-6">
        <div className="w-full flex items-center justify-between">
          <p className="text-2xl font-semibold capitalize text-headingColor relative before:absolute before:rounded-lg before:content before:w-32 before:h-1 before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-orange-400 to-orange-600 transition-all ease-in-out duration-100">
            Our fresh & healthy fruits
          </p>

          <div className="hidden md:flex gap-3 items-center">
            <ScrollMenu
              LeftArrow={LeftArrow}
              RightArrow={RightArrow}
            ></ScrollMenu>
          </div>
        </div>

        <div>
          {foodItems.map((item) => (
            <Card
              itemId={item.id} // NOTE: itemId is required for track items
              title={item.title}
              onClick={handleClick(item.id)}
              selected={isItemSelected(item.id)}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default MainContainer2;
